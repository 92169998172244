import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base.component';
import { BackendService } from '../backend.service';
import { LocalService } from '../local.service';
import { LoginViewUiState } from './login-view/login-view.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  // Query param
  returnUrl = '';

  // Child binding
  loginViewUiState: LoginViewUiState = LoginViewUiState.phone
  cachedPhoneNumber: string | null

  constructor(
    private readonly route: ActivatedRoute,
    router: Router,
    backend: BackendService,
    protected readonly localService: LocalService,
  ) {
    super(router, backend);
    const num = localService.getLocalPhoneNumber();
    this.cachedPhoneNumber = num ? num.slice(3): null;
  }

  override ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    this.loginViewUiState = LoginViewUiState.phone;
  }

  public async onClickSubmitPhoneNumber(data: {phone: string}) {
    try {
      this.loginViewUiState = LoginViewUiState.loading;
      const phone = data.phone;
      await this.backend.sendOTP(phone);
      this.snackBar = {message: `Sent to ${phone} 🎉 Please enter OTP to proceed.`, action: 'Close', config: { duration: 4000 }};
      this.loginViewUiState = LoginViewUiState.otp;
    } catch (error) {
      this.errorText = error.message;
      this.loginViewUiState = LoginViewUiState.error;
      let msg = "Error in submitting phone number. Please try again"
      if(error.toString() === "FirebaseError: Firebase: Error (auth/too-many-requests).")
      {
        msg = "You have made too many requests. Please try again after some time"
      }
      this.snackBar = {message: msg, action: 'Close', config: { duration: 4000 }};
    }
  }

  public async onClickSubmitOTP(data: {code: string}) {
    try {
      this.loginViewUiState = LoginViewUiState.loading;
      await this.backend.loginWithPhoneNumber(data.code);
      this.router.navigateByUrl(this.returnUrl);
    } catch (error) {
      this.loginViewUiState = LoginViewUiState.otp;
      let msg: string;
      if(error.toString() === "FirebaseError: Firebase: Error (auth/network-request-failed).") {
        msg = "Could not connect. Please check your connection and try again"
      }
      else if(error.toString() === "FirebaseError: Firebase: Error (auth/invalid-verification-code).") {
        msg = "Wrong OTP. Please enter it correctly"
      } else {
        msg = error.message
      }
      this.snackBar = {message: msg, action: 'Close', config: { duration: 4000 }};
    }
  }

  public async onClickReset() {
    this.loginViewUiState = LoginViewUiState.phone;
  }
}
