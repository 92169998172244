<app-toolbar-modal
(showEntry)="showEntry()"
(themeChange)="themeChange()"
></app-toolbar-modal>
<!-- (logoutConfirm)="logoutConfirm()" -->

<h1 class="pt-3 text-5xl text-blue-600 text-center" >Hello there!</h1>
<!-- <h2 class="text-2xl text-purple-900 text-center" >What did you drink today?</h2> -->


<div *ngIf="!mobileView" class=" pt-6 grid grid-cols-3 h-4/5">

  <!-- grid box 1 -->
  <div class="text-center place-self-center" >
    <button class="my-5" mat-raised-button color="primary" (click)="submit()">Make a record</button>
    <pre></pre>
    <button mat-raised-button color="accent" (click)="onFetchRecordsClick()" >Fetch Records</button>
    <pre></pre>
    <button *ngIf="pages.length > 0" class="w-auto" mat-raised-button color="warn" (click)="onDeleteAllRecords()">Delete all</button>
    <div class="text-left flex space-x-6" *ngFor="let page of pages">
      <div class="flex items-center">{{ page.date | date: 'short' }} - coffee {{ page.coffee }} - water {{ page.water }}</div>
      <div class="spacer"></div>
      <button style="width: 3vw" mat-icon-button color="warn" (click)="onClickDelete(page)"><mat-icon>delete</mat-icon></button>
      <button style="width: 3vw" mat-icon-button color="accent" (click)="onClickEdit(page)"><mat-icon>edit</mat-icon></button>
      <button style="width: 3vw; color:chartreuse" mat-icon-button color="accent" (click)="onClickCharts(page)"><mat-icon>pie_chart</mat-icon></button>
    </div>
    <pre></pre>
    <button class="my-5" mat-raised-button color="primary" (click)="onFindClick()">Find</button>
  </div>

  <!-- grid box 2 row-span-2 -->
  <div class=" text-center place-content-center h-4/5 ">
    <full-calendar style="width: 50%;" [options]='calendarOptions'></full-calendar>
  </div>

  <!-- grid box 3 place-self-center-->
  <app-quotes-modal class="sticky text-center place-self-center w-8/12 max-h-48 bg-green-300 text-black space-y-10 flex"></app-quotes-modal>

  <!-- grid box 4 -->
  <div >
    <pre></pre>
    <p class=" pl-40 text-gray-500 cursor-pointer" *ngIf="pages.length < 1" (click)="giveTotals()"> Click here to see analytics 
      <mat-icon>south</mat-icon></p>
    <div *ngIf="pages.length > 0">
      <div class="pl-32 place-self-center h-1/2 w-1/2" style="display: block;">
        <canvas baseChart width="500" height="500" 
            [data]="pieChartData"
            [labels]="pieChartLabels"
            [type]="pieChartType">
          </canvas>
      </div>
    </div>
  </div>

  <div>
    <!-- <p> 5th div</p> -->
  </div>
  
  <app-journal-modal class="w-8/12 text-center place-self-center"></app-journal-modal> 
</div>


<div *ngIf="mobileView" class="pt-4 grid grid-cols-1">

  <!-- grid box 1 -->
  <div class="place-self-center" style="text-align: center">
    <button class="w-auto" mat-raised-button color="primary" (click)="submit()">Make a record</button>
    <pre></pre>
    <button mat-raised-button class="w-auto" color="accent" (click)="onFetchRecordsClick()" >Fetch Records</button>
    <pre></pre>
    <button *ngIf="pages.length > 0" class="w-auto" mat-raised-button color="warn" (click)="onDeleteAllRecords()">Delete all</button>
    <pre></pre>
    <div class="text-left flex space-x-6" *ngFor="let page of pages">
      <div class="flex items-center">{{ page.date | date: 'dd/MM/yy' }} - coffee {{ page.coffee }} - water {{ page.water }}</div>
      <div class="spacer"></div>
      <button mat-icon-button style="width: 3vw" color="warn" (click)="onClickDelete(page)"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button style="width: 3vw" color="accent" (click)="onClickEdit(page)"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button style="width: 3vw; color:chartreuse" color="accent" (click)="onClickCharts(page)"><mat-icon>pie_chart</mat-icon></button>
    </div>
    <pre></pre>
    <button class="w30 place-self-center" mat-raised-button color="primary" (click)="onFindClick()">Find</button>
  </div>

  <!-- grid box 2 -->
  <div class="place-self-center m-5">
    <pre></pre>
    <p class="pl-4 text-gray-500 cursor-pointer" *ngIf="pages.length < 1" (click)="giveTotals()"> Click here to see analytics
      <mat-icon>south</mat-icon></p>
    <div *ngIf="pages.length > 0">
      <div class="pl-3 h-2/3" style="display: block;">
        <canvas baseChart width="auto" height="auto" 
            [data]="pieChartData"
            [labels]="pieChartLabels"
            [type]="pieChartType">
          </canvas>
      </div>
    </div>
  </div>

  <!-- grid box 3  mx-auto flex-shrink max-w-min-->
  <div class="pt-5 text-center flex-grow h-4/5 m-2">
    <full-calendar [options]='calendarOptionsMobile'></full-calendar>
  </div>

  <!-- grid box 4-->
  <div class="h-0 w-0 m-0"></div>

  <!-- grid box 5-->
  <app-quotes-modal class="m-5 sticky text-center place-self-center w-8/12 max-h-48 bg-green-300 text-black space-y-10 flex"></app-quotes-modal>
  
  <!-- grid box 6-->
  <app-journal-modal class="place-self-center text-center flex flex-shrink"></app-journal-modal> 

</div>