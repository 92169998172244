import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from '../theme.service';

@Component({
  selector: 'app-toolbar-modal',
  templateUrl: './toolbar-modal.component.html',
  styleUrls: ['./toolbar-modal.component.scss']
})
export class ToolbarModalComponent implements OnInit {

  @Output('showEntry')
  showEntryEmitter: EventEmitter<boolean> = new EventEmitter();

  @Output('logoutConfirm')
  logoutConfirmEmitter: EventEmitter<boolean> = new EventEmitter();

  @Output('themeChange')
  themeChangeEmitter: EventEmitter<boolean> = new EventEmitter();

  isDarkMode: boolean = false;

  constructor(
    public dialog: MatDialog,
    private themeService: ThemeService,
  ) {
    this.isDarkMode = this.themeService.isDarkMode(); 
   }

  ngOnInit(): void {
  }

  showEntry() { this.showEntryEmitter.emit(true) }
  // logoutConfirm() { this.logoutConfirmEmitter.emit(true) }
  themeChange() { this.themeChangeEmitter.emit(true) }

}
