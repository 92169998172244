import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { QuoteFormModalComponent } from '../quote-form-modal/quote-form-modal.component';

export interface Quote {
  _id: string,
  line: string,
  author: string,
}
@Component({
  selector: 'app-quotes-modal',
  templateUrl: './quotes-modal.component.html',
  styleUrls: ['./quotes-modal.component.scss']
})
export class QuotesModalComponent implements OnInit {
  quote: Quote | undefined = undefined;
  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.onClickNew()
  }

  async onClickAdd() { // add a new quote
    // TODO: Show a popup box form
    console.log("add button clicked")
    const dialogRef = this.dialog.open(QuoteFormModalComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) return
      const line = res.line;
      const author = res.author;
      await this.http.post(`${environment.api}/quote/`, {line, author}).toPromise()
      this.onClickNew()
    });
    // this.onClickNew()
    // await this.http.post(`${environment.api}/quote`, {line,author}).toPromise() 
  }

  async onClickNew() { // refresh to another quote
    console.log("new button clicked") 
    const quote = await this.http.get(`${environment.api}/quote/random`).toPromise()
    this.quote = quote as Quote
  }

  async onClickRemove(quote: Quote) { // remove current quote
    console.log("remove button clicked")
    await this.http.delete(`${environment.api}/quote/${quote._id}`).toPromise()
    this.onClickNew()
  }
}