import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LocalService } from './local.service';

const THEME_KEY = 'user-theme' 

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;
  private colorTheme: string = 'theme-dark';

  constructor(rendererFactory: RendererFactory2, private localService: LocalService) {
    this.renderer = rendererFactory.createRenderer(null, null)
   }

   initTheme() {
    this.colorTheme = this.localService.getThemeState();
    this.renderer.addClass(document.body, this.colorTheme);
   }
   
   toggleTheme() {
    const newTheme = this.colorTheme === 'theme-dark' ? 'theme-light' : 'theme-dark';
    this.renderer.removeClass(document.body , this.colorTheme);
    this.renderer.addClass(document.body , newTheme);
    this.setColorTheme(newTheme)
   }

   isDarkMode() {
    return this.colorTheme === 'theme-dark';
   }

   setColorTheme(theme: string) {
    this.colorTheme = theme;
    this.localService.setThemeState(theme)
    console.log("set color theme called")
   }
}
