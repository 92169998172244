import { FormArray, FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const THEME_KEY = 'user-theme' 
export const ROUTE_LOGIN = 'login';
export const ROUTE_HOME = 'home';

export const TOKEN_KEY = 'access_token';
export const USER_KEY = 'user_data';
export const PHONE_KEY = 'login_phone_key';

export enum ViewUiState {
  data,
  loading,
  error
}


/**
 * Show errors instantly
 * Error when invalid control is dirty, touched, or submitted.
 *
*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
