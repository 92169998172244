<h1 mat-dialog-title>What do you want to find out?</h1>
<div mat-dialog-content>
    <button mat-raised-button color="primary" (click)="onFindMaxCofClick()">Find Entry Of Maximum Coffee</button>
    <div *ngIf="maxCoffeePage">{{ maxCoffeePage.date | date: 'short' }} - coffee {{ maxCoffeePage.coffee }} - water {{ maxCoffeePage.water }}</div>
    <pre></pre>
    <button mat-raised-button color="primary" (click)="onFindMinCofClick()">Find Entry Of Minimum Coffee</button>
    <div *ngIf="minCoffeePage">{{ minCoffeePage.date | date: 'short' }} - coffee {{ minCoffeePage.coffee }} - water {{ minCoffeePage.water }}</div>
    <pre></pre>
    <button mat-raised-button color="primary" (click)="onFindMaxWatClick()">Find Entry Of Maximum Water</button>
    <div *ngIf="maxWaterPage">{{ maxWaterPage.date | date: 'short' }} - coffee {{ maxWaterPage.coffee }} - water {{ maxWaterPage.water }}</div>
    <pre></pre>
    <button mat-raised-button color="primary" (click)="onFindMinWatClick()">Find Entry Of Minimum Water</button>
    <div *ngIf="minWaterPage">{{ minWaterPage.date | date: 'short' }} - coffee {{ minWaterPage.coffee }} - water {{ minWaterPage.water }}</div>
    <pre></pre>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
</div>