import { JwtModule } from '@auth0/angular-jwt';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyModalComponent } from './my-modal/my-modal.component'; //1
import { AngularMaterialModule } from './angular-material.module';
import { MatButtonModule } from '@angular/material/button';
import { FindingModalComponent } from './finding-modal/finding-modal.component'; //2
import { initializeApp } from 'firebase/app'
import { NgChartsModule } from 'ng2-charts';
import { GraphsModalComponent } from './graphs-modal/graphs-modal.component'; //3
import { MatDialogModule } from '@angular/material/dialog';
import { QuotesModalComponent } from './quotes-modal/quotes-modal.component'; //5
import { ToolbarModalComponent } from './toolbar-modal/toolbar-modal.component'; //6
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSidenavModule } from '@angular/material/sidenav';
import { QuoteFormModalComponent } from './quote-form-modal/quote-form-modal.component' //7
import { JournalModalComponent } from './journal-modal/journal-modal.component';
import { TOKEN_KEY } from './utils/constants';
import { LoginComponent } from './login/login.component';
import { LoginViewComponent } from './login/login-view/login-view.component';
import { HomeComponent } from './home/home.component';
import { environment } from 'src/environments/environment';

export function tokenGetterFunction() {
  return localStorage.getItem(TOKEN_KEY);
}

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyBPFzQX4Jx9dkqcQWGL_IfavQ6DEItTf8I",
  authDomain: "drinketh-dairy.firebaseapp.com",
  projectId: "drinketh-dairy",
  storageBucket: "drinketh-dairy.appspot.com",
  messagingSenderId: "533617212750",
  appId: "1:533617212750:web:aa1808055cb37b3c26721f"
});

@NgModule({
  declarations: [
    AppComponent,
    MyModalComponent,
    FindingModalComponent,
    GraphsModalComponent,
    QuotesModalComponent,
    ToolbarModalComponent,
    QuoteFormModalComponent,
    JournalModalComponent,
    LoginComponent,
    LoginViewComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    MatButtonModule,
    NgChartsModule,
    MatDialogModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatSidenavModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetterFunction,
        headerName: 'X-Access-Token',
        authScheme: '',
        allowedDomains: [environment.jwtDomain],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [MyModalComponent, FindingModalComponent, GraphsModalComponent, QuoteFormModalComponent, QuotesModalComponent, ToolbarModalComponent ],
})
export class AppModule { }
