import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-quote-form-modal',
  templateUrl: './quote-form-modal.component.html',
  styleUrls: ['./quote-form-modal.component.scss']
})
export class QuoteFormModalComponent implements OnInit {
  line: string = ""
  author: string = ""

  constructor(
    public dialogRef: MatDialogRef<QuoteFormModalComponent>) 
  { }

  ngOnInit(): void {
  }
  submit() {
    this.dialogRef.close({
      line: this.line, 
      author: this.author
    })
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
} 
