<div *ngIf="quote" class="whitespace-pre-line flex flex-col flex-grow">
    <div class="px-4 pt-6 pb-3 break-words text-xl text-center overflow-auto">
        {{ quote.line }} 
        <br>
        -- {{ quote.author }}
    </div>
    <div class="spacer"></div>
    <div id="buttons" class="bg-gray-300 flex justify-evenly">
        <button mat-button color="primary" (click)="onClickNew()"><mat-icon>refresh</mat-icon> </button>
        <button mat-button color="primary" (click)="onClickAdd()"><mat-icon>add</mat-icon> </button>
        <button mat-button color="primary" (click)="onClickRemove(quote)"><mat-icon>delete</mat-icon> </button>
    </div>
</div>
<div *ngIf="quote==undefined">
    <button mat-button color="primary" (click)="onClickAdd()"><mat-icon>add</mat-icon> </button>
</div>