import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Page } from '../models/page.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-finding-modal',
  templateUrl: './finding-modal.component.html',
  styleUrls: ['./finding-modal.component.scss']
})
export class FindingModalComponent implements OnInit {
  
  maxCoffeePage : Page | undefined
  minCoffeePage : Page | undefined
  maxWaterPage : Page | undefined 
  minWaterPage : Page | undefined

  constructor(
    private http: HttpClient, 
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FindingModalComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
  }

  async onFindMaxCofClick() {
    console.log("max cof button clicked")
    this.maxCoffeePage = await (this.http.post(`${environment.api}/page/analytics`, {drink:'coffee', type:'max'}).toPromise() as Promise<Page>);
    console.log(this.maxCoffeePage) 
  }

  async onFindMinCofClick() {
    console.log("min cof button clicked")
    this.minCoffeePage = await (this.http.post(`${environment.api}/page/analytics`, {drink:'coffee', type:'min'}).toPromise() as Promise<Page>);
    console.log(this.minCoffeePage) 
  }

  async onFindMaxWatClick() {
    console.log("max water button clicked")
    this.maxWaterPage = await (this.http.post(`${environment.api}/page/analytics`, {drink:'water', type:'max'}).toPromise() as Promise<Page>);
    console.log(this.maxWaterPage) 
  }

  async onFindMinWatClick() {
    console.log("min water button clicked")
    this.minWaterPage = await (this.http.post(`${environment.api}/page/analytics`, {drink:'water', type:'min'}).toPromise() as Promise<Page>);
    console.log(this.minWaterPage) 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
