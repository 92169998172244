import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FindingModalComponent } from '../finding-modal/finding-modal.component'; //1
import { MyModalComponent } from '../my-modal/my-modal.component'; //2
import { CalendarOptions, defineFullCalendarElement } from '@fullcalendar/web-component';
import dayGridPlugin from '@fullcalendar/daygrid';
import { calendar } from 'ngx-bootstrap/chronos/moment/calendar';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import { GraphsModalComponent } from '../graphs-modal/graphs-modal.component'; //3
import { SelectMultipleControlValueAccessor } from '@angular/forms';
import { ThemeService } from '../theme.service';
import { BackendService } from '../backend.service';
import { Router } from '@angular/router';
import { Page } from '../models/page.model';
import { LoginComponent } from '../login/login.component';
import { JournalModalComponent } from '../journal-modal/journal-modal.component';
import { environment } from 'src/environments/environment';
import { BaseViewComponent } from '../base/base-view/base-view.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AUTO_STYLE } from '@angular/animations';
// import { BaseChartDirective, NgChartsModule } from 'ng2-charts';

defineFullCalendarElement();



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseViewComponent implements OnInit {
  pages: Array<Page> = []
  
  constructor(
    public router: Router,
    private http: HttpClient, 
    public dialog: MatDialog,
    private themeService: ThemeService,
    public backend: BackendService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
    ) {
    super(breakpointObserver, snackBar);
  }

// calender :

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin], 
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,dayGridDay'
    },
    // footerToolbar: {
    //   right: 'dayGridMonth,dayGridWeek,dayGridDay'
    // },
    initialView: 'dayGridMonth',
    select: this.handleDateClick.bind(this), // bind is important!
    // eventClick: this.handleDateClick.bind(this),
    // eventsSet: this.handleDateClick.bind(this),
    selectable: true,
    events: [
      // { title: 'event 1', date: '2022-10-28' },
      // { title: 'event 2', date: '2022-10-28' }
    ]
  };

  // mobile calender :

  calendarOptionsMobile: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    titleFormat: { year: 'numeric', month: 'short'},
    headerToolbar: {
      left: 'prev,next',
      center: '',
      right: 'title',
    },
    footerToolbar: {
      left: 'today',
      center: '',
      right: 'dayGridMonth,dayGridWeek,dayGridDay'
    },
    initialView: 'dayGridMonth',
    select: this.handleDateClick.bind(this), // bind is important!
    // eventClick: this.handleDateClick.bind(this),
    // eventsSet: this.handleDateClick.bind(this),
    selectable: true,
    events: [
      // { title: 'event 1', date: '2022-10-28' },
      // { title: 'event 2', date: '2022-10-28' }
    ]
  };

//pie chart :

  pieChartLabels: string[] = ['Coffee', 'Water'];
  pieChartData = {
    labels: [
      'Coffee',
      'Water'
    ],
    datasets: [{
      data: [0, 0],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235'
      ],
      hoverOffset: 4
    }]
  };
  pieChartType: any = 'pie';

// functions :

  handleDateClick(arg: any) {
    alert('date click! ' + arg.startStr)
  } //1
  

  ngOnInit(): void {
    // this.giveTotals();
  } //2

  async onFetchRecordsClick() {
    console.log("fetching records")
    this.pages = await (this.http.get(`${environment.api}/page`).toPromise() as Promise<Array<Page>>);
    let events = this.pages.flatMap((p: Page) => {
      return [
        { title: `☕ Coffee: ${p.coffee}`, date: this.dateToDateString(new Date(p.date)), color: '#6f4e37' },
        { title: `💧 Water: ${p.water}`, date: this.dateToDateString(new Date(p.date)), color: '#0f5e9c' },
      ]
    })
    const sumC = this.pages.map(p => p.coffee).reduce((a, b) => a + b, 0)
    const sumW = this.pages.map(p => p.water).reduce((a, b) => a + b, 0)
    this.pieChartData.datasets[0].data = [sumC, sumW];
    this.calendarOptions = {
      ...this.calendarOptions,
      events: events,
    }
    this.calendarOptionsMobile = {
      ...this.calendarOptionsMobile,
      events: events,
    }
  } //3

  async giveTotals() {
    this.pages = await (this.http.get(`${environment.api}/page`).toPromise() as Promise<Array<Page>>);
    const sumC = this.pages.map(p => p.coffee).reduce((a, b) => a + b, 0)
    const sumW = this.pages.map(p => p.water).reduce((a, b) => a + b, 0)
    this.pieChartData.datasets[0].data = [sumC, sumW];  
    return [ sumC, sumW ];
  } //4

  onClickCharts (page:Page){
    console.log("charts button clicked")
    const dialogRef = this.dialog.open(GraphsModalComponent, {
      width: '500px',
      data: { water: page.water, coffee: page.coffee }
    });
  } //5

  async onClickChart(){
    console.log("charts button")
    let [sumC, sumW] = await this.giveTotals();
    const dialogRef = this.dialog.open(GraphsModalComponent, {
      width: '500px',
      data : { coffee: sumC, water: sumW }
    });
  } //6

  dateToDateString = (d: Date) => d.toISOString().substr(0, 10) //7

  async submit() {
    const dialogRef = this.dialog.open(MyModalComponent, {
      width: '400px',
      data: { date: this.dateToDateString(new Date()), water: 0, coffee: 0 }
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) return
      const date = res.date;
      const water = res.water;
      const coffee = res.coffee;
      console.log("entry made", date,water,coffee)
      console.log("form submitted")
      await this.http.post(`${environment.api}/page`, {date,water,coffee}).toPromise()
      this.onFetchRecordsClick()
    });
  } //8

  onFindClick() {
    console.log("find button clicked")
    const dialogRef = this.dialog.open(FindingModalComponent, {
      width: '400px',
    });
  } //9

  async onClickDelete(page:Page) {
    await this.http.delete(`${environment.api}/page/${page._id}`).toPromise()
    this.onFetchRecordsClick()
  } //10

  async onDeleteAllRecords() {
    await this.http.delete(`${environment.api}/page`).toPromise()
    this.onFetchRecordsClick()
  } //11

  onClickEdit(page:Page) {
    console.log("edit button clicked")
    const dialogRef = this.dialog.open(MyModalComponent, {
      width: '400px',
      data: { date: this.dateToDateString(new Date(page.date)), water:page.water, coffee: page.coffee }
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) return
      const date = res.date;
      const water = res.water;
      const coffee = res.coffee;
      console.log("edit done", date,water,coffee)
      await this.http.patch(`${environment.api}/page/${page._id}`, {date,water,coffee}).toPromise()
      this.onFetchRecordsClick()
    });
  } //12
  
  showEntry() {
    console.log("entry trials")
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',
      height: '400px',
    });
  } //13

  logoutConfirm() {
    console.log("logout confirmaton needed")
  } //14

  themeChange() {
    console.log("change the theme")
    this.themeService.toggleTheme();
  } //15

  onClickOpenJournal() {
    console.log("clicked open journal")
    const dialogRef = this.dialog.open(JournalModalComponent, {
      width: '400px',
    });
  }

}