import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ROUTE_HOME, ROUTE_LOGIN } from './utils/constants';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: ROUTE_HOME },
  {
    path: ROUTE_LOGIN,
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: ROUTE_HOME,
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: ROUTE_HOME }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      enableTracing: false // set to true to debug
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
