<div class="whitespace-pre-line flex flex-col items-stretch px-4 py-4">
  <div class="py-1 bg-gray-300 text-black">{{ formGp.controls['date'].value | date: 'dd/MM/yyyy' }} {{ valuesChanged ? '(unsaved)': '' }} </div>
  <mat-progress-bar *ngIf="inProgressCounter" mode="indeterminate"></mat-progress-bar>
  <form [formGroup]="formGp" style="min-height: 150px" class="flex flex-col items-stretch bg-red-600">
    <textarea
    [ngClass]="{ 'changed' : valuesChanged == true, 'unchanged' : valuesChanged == false}"
    [disabled]="inProgressCounter!=0"
    matInput
    class="bg-green-300 break-words overflow-auto placeholder-gray-700 text-black flex-grow"
    placeholder=" How was your day?"
    formControlName="stuff">
    </textarea>
  </form>
  <div class="spacer"></div>
  <div id="buttons" class="bg-gray-300">
    <div class="float-left">
      <button mat-button color="primary" [disabled]="inProgressCounter!=0" (click)="prevDay()"><mat-icon>arrow_back_ios</mat-icon></button>
      <button mat-button color="primary" [disabled]="inProgressCounter!=0" (click)="nextDay()"><mat-icon>arrow_forward_ios</mat-icon></button>
    </div>
    <div class="float-right">
      <button mat-button color="primary" [disabled]="inProgressCounter!=0" (click)="reset()"><mat-icon>restart_alt</mat-icon></button>
      <button mat-button color="primary" [disabled]="inProgressCounter!=0" (click)="removeAll()"><mat-icon>backspace</mat-icon></button>
      <button mat-button color="primary" [disabled]="inProgressCounter!=0 || valuesChanged==false" (click)="submit()"><mat-icon>check</mat-icon></button>
    </div>
  </div>
</div>