<h1 mat-dialog-title>Record editor : </h1>
<div mat-dialog-content>
    <form (ngSubmit)="submit()">
        <div class="form-group">
          <label for="date">Date  :  </label>
          <input type="Date" name="date" class="form-control" [(ngModel)]="data.date">
        </div>
        <pre></pre>
        <div class="form-group">
          <label for="water">Water  :  </label>
          <input type="number" name="water" class="form-control" [(ngModel)]="data.water">
        </div>
        <pre></pre>
        <div class="form-group">
          <label for="coffee">Coffee  :  </label>
          <input type="number" name="coffee" class="form-control" [(ngModel)]="data.coffee">
        </div>
      </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="submit()" cdkFocusInitial>OK</button>
</div>