import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


export interface journalEntry {
  _id: string,
  date: Date,
  jEntry: string,
}

@Component({
  selector: 'app-journal-modal',
  templateUrl: './journal-modal.component.html',
  styleUrls: ['./journal-modal.component.scss']
})
export class JournalModalComponent implements OnInit {
  journal_entry!: journalEntry;
  formGp!: FormGroup;
  inProgressCounter = 0;
  journal!: journalEntry | null;
  valuesChanged!:boolean;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    )
   { }

  ngOnInit(): void {
    const today = new Date();
    this.formGp = this.fb.group({
      date: today,
      stuff:'',
    })
    this.fetchJournalForDate(today);
    // if (this.formGp.controls['stuff'].untouched) {this.valuesChanged=false}
    this.formGp.controls['stuff'].valueChanges.subscribe(value => this.valuesChanged = value != (this.journal?.jEntry || ''));
  }

  async fetchJournalForDate(date: Date) {
    this.formGp.controls['date'].setValue(date)
    const d = moment(date).startOf('day').format('MM-DD-YYYY')
    this.inProgressCounter++
    this.journal = await (this.http.get(`${environment.api}/journal/date/${d}`).toPromise() as Promise<journalEntry | null>)
    if(this.journal) {
      this.formGp.controls['stuff'].setValue(this.journal.jEntry)
    } else {
      this.formGp.controls['stuff'].setValue('')
    }
    this.inProgressCounter--
  }

  prevDay() {
    console.log("prevDay button")
    const prevDay = moment(this.formGp.value.date).subtract(1, 'day').toDate()
    this.fetchJournalForDate(prevDay)
  }

  nextDay() {
    console.log("nextDay button")
    const nextDay = moment(this.formGp.value.date).add(1, 'day').toDate()
    this.fetchJournalForDate(nextDay)
  }

  removeAll() {
    console.log("remove all in textbox button")
    this.formGp.controls['stuff'].setValue('')
    // if (this.journal == null) {
    //   this.valuesChanged = false
    // }
  }

  async submit() {
    console.log("submit button")
    const date = moment(this.formGp.value.date).startOf('day').format('MM-DD-YYYY')
    const jEntry = this.formGp.value.stuff;
    console.log("entry made", date,jEntry)
    if(this.journal) {
      await this.http.patch(`${environment.api}/journal/${this.journal._id}`, {date,jEntry}).toPromise()
    }
    else {
      await this.http.post(`${environment.api}/journal`, {date,jEntry}).toPromise()
    }
    console.log("form submitted")
    // this.valuesChanged = false;
    this.ngOnInit()
  }

  reset() {
    this.formGp.controls['stuff'].setValue(this.journal?.jEntry || '')
  }
}