import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ROUTE_HOME, ROUTE_LOGIN } from '../utils/constants';
import { BackendService } from '../backend.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanActivate {

  constructor(
    private backend: BackendService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkAuthenticated(state, route)
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthenticated(state, route)
  }

  checkAuthenticated(state: RouterStateSnapshot, route: ActivatedRouteSnapshot) {
    // redirect if not logged in
    const authenticated: boolean = this.backend.authenticationState.getValue();
    if (!authenticated) {
      // not logged in so redirect to login page with the return url and return false
      this.router.navigate([ROUTE_LOGIN], { queryParams: { returnUrl: state.url }});
      return false;
    }
    return true;
  }
}
