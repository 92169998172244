import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ViewUiState } from 'src/app/utils/constants';

@Component({
  selector: 'app-base-view',
  templateUrl: './base-view.component.html',
  styleUrls: ['./base-view.component.scss']
})
export class BaseViewComponent {
  mobileView = false; // Used to alter UI for mobile view
  scrollingDown: boolean = false; // Used to hide toolbar, tabs and move Fab

  @Input('uiState')
  uiState: ViewUiState = ViewUiState.loading;

  @Input('errorText')
  errorText: string = '';

  @Input('snackbar')
  set snackbar(input: {message: string, action?: string, config?: MatSnackBarConfig} | undefined) {
    if(input) this.snackBar.open(input.message, input.action, input.config);
  }

  ViewUiState = ViewUiState; // For access in HTML

  constructor(
    protected readonly breakpointObserver: BreakpointObserver,
    protected readonly snackBar: MatSnackBar,
  ) {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    .subscribe(result => this.mobileView = result.matches);
  }
}
