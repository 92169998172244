import { Component, OnInit } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ROUTE_LOGIN, ViewUiState } from 'src/app/utils/constants';
import { BackendService } from 'src/app/backend.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  // Child binding
  uiState: ViewUiState;
  errorText: string;
  snackBar: {message: string, action?: string, config?: MatSnackBarConfig} | undefined

  constructor(
    protected readonly router: Router,
    protected readonly backend: BackendService) {
    this.uiState = ViewUiState.loading;
    this.errorText = "";
  }

  ngOnInit(): void {
  }

  public onClickLogout() {
    this.backend.logout();
    this.router.navigate([ROUTE_LOGIN]);
  }
}
