import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Page } from '../models/page.model';

@Component({
  selector: '.app-graphs-modal',
  templateUrl: './graphs-modal.component.html',
  styleUrls: ['./graphs-modal.component.scss']
})
export class GraphsModalComponent implements OnInit {

  constructor(
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  current_values : Page | undefined
  coffee_value : number = this.data.coffee
  water_value : number = this.data.water

  ngOnInit(): void {
  }

  public pieChartLabels: string[] = ['Coffee', 'Water'];
  public pieChartData = {
    labels: [
      'Coffee',
      'Water'
    ],
    datasets: [{
      data: [this.coffee_value, this.water_value],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235'
      ],
      hoverOffset: 4
    }]
  };
  public pieChartType: any = 'pie';
  
}