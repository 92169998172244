import * as fauth from 'firebase/auth';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, NEVER } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalService } from 'src/app/local.service';
import { ROUTE_LOGIN } from './utils/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  public authenticationState = new BehaviorSubject(false);
  private API_URL = environment.api;

  constructor(
    private http: HttpClient,
    private helper: JwtHelperService,
    private localService: LocalService,
    private router: Router,
  ) {
    this.checkAuth();
  }

  private checkAuth() {
    if (this.checkToken()) this.authenticationState.next(true);
  }

  private checkToken() {
    const token = this.localService.getLocalToken();
    const isExpired = this.helper.isTokenExpired(token);
    if (isExpired) {
      this.logout();
      return false;
    }
    return true;
  }

  confirmationResult: any;

  public async sendOTP(phone: string) {
    const appVerifier = (window as any).recaptchaVerifier;
    const auth = fauth.getAuth();
    this.confirmationResult = await fauth.signInWithPhoneNumber(auth, phone, appVerifier);
    this.localService.setLocalPhoneNumber(phone);
  }

  public async loginWithPhoneNumber(code: string) {
    const firebaseUser = await this.confirmationResult.confirm(code);
    const idToken = await firebaseUser.user.getIdToken(/* forceRefresh */ true)
    const data = await this.post("/users/login/otp", { idToken: idToken }).toPromise();
    // save data and map model
    this.localService.setLocalToken(data.token);
    this.checkAuth(); // Mark state as logged in
  }

  public async logout() {
    this.localService.removeLocalStorage();
    this.firebaseLogout();
    this.authenticationState.next(false);
    // not logged in so redirect to login page with the return url
    this.router.navigate([ROUTE_LOGIN], { queryParams: { returnUrl: this.router.routerState.snapshot.url }});
  }

  //////////////////////////// Firebase functions ///////////////////////

  private firebaseLogout() {
    return fauth.signOut(fauth.getAuth());
  }

  //////////////////////////// Network functions ///////////////////////

  public get(url: string) {
    return this.http.get<any>(`${this.API_URL}${url}`)
      .pipe(
        catchError(e => {
          const status = e.status;
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public getWithOptions(url: string, options: any) {
    return this.http.get<any>(`${this.API_URL}${url}`, options)
      .pipe(
        catchError(e => {
          const status = e.status;
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public post(url: string, data: any) {
    return this.http.post<any>(`${this.API_URL}${url}`, data)
      .pipe(
        catchError(e => {
          const status = e.status;
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public delete(url: string) {
    return this.http.delete<any>(`${this.API_URL}${url}`)
      .pipe(
        catchError(e => {
          const status = e.status;
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public put(url: string, data: any) {
    return this.http.put<any>(`${this.API_URL}${url}`, data)
      .pipe(
        catchError(e => {
          const status = e.status;
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public postWithOptions(url: string, data: any, options: any) {
    return this.http.post<any>(`${this.API_URL}${url}`, data, options)
      .pipe(
        catchError(e => {
          const status = e.status;
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public externalGetWithOptions(url: string, options: any) {
    return this.http.get<any>(url, options)
  }

  public externalPostWithOptions(url: string, data: any, options: any) {
    return this.http.post<any>(url, data, options)
  }
}
