<h1 mat-dialog-title>Add a new quote here : </h1>
<div mat-dialog-content>
    <form (ngSubmit)="submit()">
        <div class="form-group">
          <label for="line">Quote  :  </label>
          <input type="string" name="line" class="form-control" [(ngModel)]="line">
        </div>
        <pre></pre>
        <div class="form-group">
          <label for="author">By  :  </label>
          <input type="string" name="author" class="form-control" [(ngModel)]="author">
        </div>
      </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="submit()" cdkFocusInitial>OK</button>
</div>