import { Injectable } from '@angular/core';
import { THEME_KEY, PHONE_KEY, TOKEN_KEY } from './utils/constants';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor() {}

  public getLocalToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  public setLocalToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  public removeLocalToken() {
    localStorage.removeItem(TOKEN_KEY);
  }

  public setLocalPhoneNumber(phone: string) {
    localStorage.setItem(PHONE_KEY, phone);
  }

  public getLocalPhoneNumber() {
    return localStorage.getItem(PHONE_KEY);
  }

  public setThemeState(theme: string) {
    localStorage.setItem(THEME_KEY, theme);
  }

  public getThemeState() {
    if(localStorage.getItem(THEME_KEY)) return localStorage.getItem(THEME_KEY)!;
    return 'theme-light'
  }

  public removeLocalStorage() {
    const phone = this.getLocalPhoneNumber();
    localStorage.clear();
    if(phone) this.setLocalPhoneNumber(phone);
  }
}
