<div id="login-page" class="background">
  <div class="login-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center">
    <img style="width: 100%; max-width:400px;">
    <mat-card>
      <div [hidden]="loginViewUiState !== LoginViewUiState.phone">
        <mat-card-header>
          <mat-card-title>Login</mat-card-title>
          <!-- <mat-card-subtitle>Monitoring every drop of water in realtime</mat-card-subtitle> -->
        </mat-card-header>

        <mat-card-content>
          <form [formGroup]="phoneForm">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Phone Number</mat-label>
              <span matPrefix>+91&nbsp;</span>
              <input matInput
              id="phone-input"
              type="tel"
              [errorStateMatcher]="ocdErrorMatcher"
              formControlName="phone">
              <mat-hint>OTP will be sent via SMS</mat-hint>
              <mat-error id="phone-error" *ngIf="phoneForm.controls['phone'].invalid">{{getErrorMessage(phoneForm.controls['phone'])}}</mat-error>
            </mat-form-field>
            <div id="recaptcha-container">
            </div>
            <button id="phone-submit" mat-stroked-button
            color="primary" type="submit"
            [disabled]="!phoneForm.valid || !recaptchaVerified"
            (click)="onClickSubmitPhone()">Send OTP</button>
          </form>
          <app-spinner *ngIf="phoneForm.pending"></app-spinner>
        </mat-card-content>
      </div>

      <div [hidden]="loginViewUiState !== LoginViewUiState.otp">
        <mat-card-header>
          <mat-card-title>Login</mat-card-title>
          <!-- <mat-card-subtitle>Monitoring every drop of water in realtime</mat-card-subtitle> -->
        </mat-card-header>

        <mat-card-content>
          <form [formGroup]="otpForm" (ngSubmit)="onClickSubmitOTP()" autocomplete="off">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>One Time Password (OTP)</mat-label>
              <input matInput id="otp-input" type="tel" [errorStateMatcher]="ocdErrorMatcher" formControlName="otp">
              <mat-hint>OTP SMS may take a few seconds</mat-hint>
              <mat-error id="otp-error" *ngIf="otpForm.controls['otp'].invalid">{{getErrorMessage(otpForm.controls['otp'])}}</mat-error>
            </mat-form-field>
            <button id="otp-submit" mat-stroked-button color="primary" type="submit" [disabled]="!otpForm.valid">Submit OTP</button>
            <div style="width: 100%; text-align: center; padding-top: 8px;">
              <a style="cursor: pointer;" (click)="onClickReset()">Change number/resend OTP</a>
            </div>
          </form>
          <app-spinner *ngIf="otpForm.pending"></app-spinner>
        </mat-card-content>
      </div>

      <div [hidden]="loginViewUiState !== LoginViewUiState.loading">
        <mat-spinner id="submit-spinner" style="margin: auto;"></mat-spinner>
      </div>

      <div [hidden]="loginViewUiState !== LoginViewUiState.error">
        <mat-card-header>
          <mat-card-title>Error</mat-card-title>
          <mat-card-subtitle id="error-text">{{ errorText }}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <button mat-stroked-button color="primary" (click)="onClickReset()">Reset</button>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
